
import { IonButton, IonContent, IonImg, IonPage } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import { bookingForm } from '@/store';
import { openToast } from '@/composables/ionicControllers';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: { IonButton, IonContent, IonImg, IonPage },
  setup() {
    const jobId = useRouter().currentRoute.value.params.id;
    const status = ref<string>('');
    const isLoading = ref<boolean>(false);
    const {
      verifyJobCompletionStatus,
      jobCompletionConfirmation,
    } = bookingForm;
    onMounted(async () => {
      isLoading.value = true;
      const result = await verifyJobCompletionStatus(
        atob(jobId as string),
      );

      if (result.ok) {
        if (result.data.status !== 'DONE') {
          status.value = 'FAIL';
        }
        status.value = result.data.status;
        isLoading.value = false;
      } else {
        isLoading.value = false;
        status.value = 'FAIL';
        await openToast(result.message as string);
      }
    });

    const onConfirm = async () => {
      isLoading.value = true;
      const result = await jobCompletionConfirmation(
        atob(jobId as string),
      );
      if (result.ok) {
        status.value = 'DONE';
        isLoading.value = false;
      } else {
        isLoading.value = false;
        status.value = 'FAIL';
      }
    };

    return {
      status,
      isLoading,
      onConfirm,
    };
  },
});
